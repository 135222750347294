import { Action, createReducer, on } from '@ngrx/store';
import * as HomeActions from '../home.actions';
import * as AccountActions from '@account/account.actions';

import { Notification } from '@shared/models/Account';
import { SuperhumanQuestion, TechnicalIssuesReporting, PromoCampaignItem } from '@shared/models/Home';

import { findCurrentQuestionID, mapTechnicalIssues } from '../home.utils';

export const initialState: HomeState = {
  customerNotifications: [],
  superhumanSurvey: [],
  currentSuperhumanSurveyQuestionID: 0,
  promoCampaigns: null,
  technicalIssues: {
    de3b: false,
    ch3b: false,
    ch3a: false,
    chkid: false
  }
};

const HomeReducer = createReducer(
  initialState,
  on(HomeActions.getCustomerNotificationsSuccess, (state, action) => ({
    ...state,
    customerNotifications: action.data
  })),
  on(HomeActions.getSuperhumanSurveySuccess, (state, action) => ({
    ...state,
    superhumanSurvey: action.survey,
    currentSuperhumanSurveyQuestionID: action.nextQuestionID ? action.nextQuestionID : findCurrentQuestionID(action.survey)
  })),
  on(HomeActions.setCurrentSuperhumanSurveyID, (state, action) => ({
    ...state,
    currentSuperhumanSurveyQuestionID: action.id
  })),
  on(HomeActions.getSuperhumanSurveyFailed, (state) => ({
    ...state,
    superhumanSurvey: []
  })),
  on(HomeActions.getAllReportingsStatusSuccess, (state, action) => ({
    ...state,
    technicalIssues: mapTechnicalIssues(action.data)
  })),
  on(HomeActions.getPromoCampaignsSuccess, (state, action) => ({
    ...state,
    promoCampaigns: action.data.data
  })),
  on(HomeActions.setCampaignParticipationSuccess, (state, action) => ({
    ...state,
    promoCampaigns: action.data
  })),
  on(HomeActions.getPromoCampaignsFail, (state) => ({
    ...state,
    promoCampaigns: []
  })),
  // NgRx store clean up after logout
  on(AccountActions.customerLogout, () => ({ ...initialState })),
  on(AccountActions.customerLogoutAndUnpairDevice, () => ({ ...initialState }))
);

export function reducerHome(state: HomeState | undefined, action: Action) {
  return HomeReducer(state, action);
}

export interface HomeState {
  customerNotifications: Notification[];
  superhumanSurvey: SuperhumanQuestion[];
  currentSuperhumanSurveyQuestionID: number;
  technicalIssues: TechnicalIssuesReporting;
  promoCampaigns: PromoCampaignItem[];
}
