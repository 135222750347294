import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { getRiskType, RISK_RATIO_TO_SLIDER_STEP , getGroupedInvestments } from '@inyova/grow';
import { AmplitudeService } from '@inyova/inyova-shared';
import { IonContent, ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as InyovaGrowActions from '@grow/inyova-grow.actions';
import * as fromInyovaGrow from '@grow/inyova-grow.reducers';

import { GrowProject, GrowProjectStage, GrowProjectStatus, InyovaGrowAccount } from '@app/shared/models/Grow';

enum InvestmentSteps {
  SETUP,
  CONFIRM,
  DONE
}

const stepsMap = {
  [InvestmentSteps['SETUP']]: 'step_1',
  [InvestmentSteps['CONFIRM']]: 'step_2',
  [InvestmentSteps['DONE']]: 'step_3'
};

@Component({
  selector: 'app-inyova-grow-investment-modal',
  templateUrl: './inyova-grow-investment-modal.component.html',
  styleUrls: ['./inyova-grow-investment-modal.component.scss']
})
export class InyovaGrowInvestmentModalComponent implements OnInit, OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  @Input() growAccount: InyovaGrowAccount;
  @Input() projectId: string;
  @Input() language: string;
  activeHeaderStep = 1;
  currentStep: InvestmentSteps;
  investmentSteps = InvestmentSteps;
  selectedAmount: null | number = null;
  project$: Observable<GrowProject>;
  projectFinishStatus: 'success' | 'reached' | '';
  loading = false;
  riskLevel = 'balanced';
  inyovaGrowProjectStage = GrowProjectStage;

  @ViewChild(IonContent) content: IonContent;

  constructor(
    private modalController: ModalController,
    private el: ElementRef,
    private store: Store,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit() {
    this.currentStep = this.investmentSteps.SETUP;
    this.project$ = this.store.select(fromInyovaGrow.selectGrowProjectById(this.projectId));
    this.riskLevel = getRiskType(RISK_RATIO_TO_SLIDER_STEP[this.growAccount.projects_percentage]);

    this.store
      .select(fromInyovaGrow.selectGrowProjectById(this.projectId))
      .pipe(take(1))
      .subscribe((project: GrowProject) => {
        const isEligibleForInvesting = this.isEligibleForInvesting(this.growAccount, project);
        const { projects_percentage: projectsPercentage } = this.growAccount;
        const {
          investments,
          account_specific: { recommended_additional_amount: recommendedAdditionalAmount },
          minimum_funding: minimumFunding
        } = project;

        this.amplitudeService.trackEvent('Grow Investment Flow Started', {
          investment_flow_step: isEligibleForInvesting ? 'step_1' : projectsPercentage === 0 ? 'risk_profile_step' : 'insufficient_funds_step',
          investment_type:
            getGroupedInvestments(investments)[0].status === GrowProjectStatus.INTERESTED ? 'first_investment' : 'additional_investment',
          ...(isEligibleForInvesting && {
            investment_amount_type:
              recommendedAdditionalAmount !== 0 && recommendedAdditionalAmount >= minimumFunding ? 'suggested_amount_chosen' : 'manual_amount_entered'
          })
        });
      });

    this.store
      .select(fromInyovaGrow.selectInyovaGrowFinishStatus)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((status) => (this.projectFinishStatus = status));

    this.store
      .select(fromInyovaGrow.selectInyovaGrowInvestmentDone)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((success: boolean) => {
        if (success) {
          void this.content.scrollToTop(0);
          this.activeHeaderStep = 3;
          this.currentStep = this.investmentSteps.DONE;
        }
      });

    this.store
      .select(fromInyovaGrow.selectInyovaGrowInvestmentLoading)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });
  }

  ngOnDestroy() {
    this.store.dispatch(InyovaGrowActions.finishGrowProjectInvestmentReset());
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onDismiss(growAccount: InyovaGrowAccount, project: GrowProject) {
    this.modalController.dismiss();
    this.amplitudeService.trackEvent('Grow Investment Flow Closed', {
      investment_flow_step: this.isEligibleForInvesting(growAccount, project)
        ? stepsMap[this.currentStep]
        : growAccount.projects_percentage === 0
          ? 'risk_profile_step'
          : 'insufficient_funds_step'
    });
  }

  onDepositFund() {
    this.amplitudeService.trackEvent('Grow Deposit Opened', { deposit_opened_from: 'insufficient_funds_ste' });
    this.modalController.dismiss({ openDepositModal: true });
  }

  onSubmitAmount({ amount }: { amount: number }) {
    void this.content.scrollToTop(0);
    this.activeHeaderStep = 2;
    this.currentStep = this.investmentSteps.CONFIRM;
    this.selectedAmount = amount;
  }

  onSubmitInvestment({ valid }: { valid: boolean }) {
    if (!valid) {
      setTimeout(() => {
        const firstInvalidCheckbox: HTMLElement | null = this.el.nativeElement.querySelector('.has-error');
        if (firstInvalidCheckbox !== null) {
          void this.content.scrollToPoint(0, firstInvalidCheckbox.offsetTop, 350);
        }
        return;
      }, 0);
      return;
    }

    if (this.loading) {
      return;
    }
    this.store.dispatch(InyovaGrowActions.finishGrowProjectInvestment({ projectId: this.projectId, amount: this.selectedAmount }));
  }

  isEligibleForInvesting(growAccount: InyovaGrowAccount, project: GrowProject): boolean {
    return (
      growAccount.projects_percentage !== 0 &&
      project.account_specific.recommended_additional_amount !== null &&
      project.account_specific.maximum_additional_amount > 0
    );
  }
}
