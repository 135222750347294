import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from '@inyova/models';
import { LoadingController, ModalController } from '@ionic/angular';
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumberType } from 'google-libphonenumber';
import { CountryISO } from 'ngx-intl-tel-input-gg';

import { Store } from '@ngrx/store';
import { noop, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as fromAccount from '@account/account.reducers';

import { EUROPE_COUNTRIES } from '@app/app.constants';
import { State } from '@shared/models/State';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-account-phone-modal',
  styleUrls: ['./account-phone-modal.component.scss'],
  templateUrl: './account-phone-modal.component.html'
})
export class AccountPhoneModalComponent implements OnDestroy, AfterViewInit {
  @Input() message: string;
  customer: Customer;
  isCHCustomer: boolean;
  customerDetailsForm: FormGroup;
  inputOptions: { [key: string]: string | boolean | string[] | ((customPlaceholder: string) => string) };
  has3AAccountMinProspect: boolean;
  onlyCountries = EUROPE_COUNTRIES;
  phoneNumber: string | null = null;
  mobilePhonePlaceholder: string;

  readonly onDestroy$ = new Subject<void>();

  get phoneControl(): AbstractControl {
    return this.customerDetailsForm.get('phone');
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private store: Store<State>,
    private trackingService: TrackingService
  ) {
    this.customerDetailsForm = this.fb.group({
      phone: ['', [Validators.required]]
    });

    this.store
      .select(fromAccount.selectCustomer)
      .pipe(withLatestFrom(this.store.select(fromAccount.selectHas3AAccountMinProspect)), takeUntil(this.onDestroy$))
      .subscribe(([customer, has3AAccountMinProspect]: [customer: Customer, has3AAccountMinProspect: boolean]) => {
        this.customer = customer;
        this.has3AAccountMinProspect = has3AAccountMinProspect;
        this.isCHCustomer = this.customer.app_location === CountryISO.Switzerland;

        const phoneNumberObject = this.initializePhoneNumber(
          this.customer.verified_phone,
          this.customer.unverified_phone,
          this.has3AAccountMinProspect
        );
        this.phoneControl.setValue(phoneNumberObject);

        this.inputOptions = {
          initialCountry: this.customer.app_location,
          separateDialCode: true,
          preferredCountries: !this.isCHCustomer ? [CountryISO.Germany] : [],
          onlyCountries: !this.isCHCustomer ? this.onlyCountries : [CountryISO.Switzerland]
        };
        // disable phone input
        if (this.customer.provider === 'email') {
          return;
        }
        this.phoneControl.disable();
      });
  }

  ngAfterViewInit() {
    this.updateMobilePhonePlaceholder(this.phoneControl.getRawValue()?.countryCode ?? this.customer.app_location);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.customerDetailsForm.reset();
    });
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  dismiss(): void {
    this.modalController.dismiss({ dismissed: true }).finally(noop);
    this.modalController.dismiss(undefined, undefined, 'password-modal').finally(noop);
  }

  async onUpdatePhone(): Promise<void> {
    if (this.customerDetailsForm.invalid) {
      this.customerDetailsForm.markAllAsTouched();
      return;
    }
    this.phoneNumber = this.phoneControl.getRawValue().e164Number;

    this.trackingService.trackActivity('[Button] Save updated phone');

    const loading = await this.loadingController.create();
    await loading.present();
    this.store.dispatch(
      AccountActions.updatePhone({
        phoneNumber: this.phoneNumber,
        needsVerification: this.has3AAccountMinProspect
      })
    );
  }

  initializePhoneNumber(
    verifiedPhone: string | null,
    unverifiedPhone: string | null,
    has3AAccountMinProspect: boolean
  ): { number: string; countryCode: string; toString } | null {
    const number = has3AAccountMinProspect ? verifiedPhone || unverifiedPhone : unverifiedPhone || verifiedPhone;
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parse(number);

    return {
      number: phoneUtil
        .format(phoneNumber, PhoneNumberFormat.NATIONAL)
        .replace(/^\s*0+/, '')
        .trim(),
      countryCode: phoneUtil.getRegionCodeForNumber(phoneNumber)
    };
  }

  updateMobilePhonePlaceholder(countryCode: string) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    this.mobilePhonePlaceholder = PhoneNumberUtil.getInstance().format(
      phoneUtil.getExampleNumberForType(countryCode, PhoneNumberType.MOBILE),
      PhoneNumberFormat.National
    );
  }

  onCountryChange(event: { iso2: string }): void {
    this.updateMobilePhonePlaceholder(event.iso2);
  }
}
